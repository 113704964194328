import * as R from 'ramda'
import {GatsbyImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import {partnerContentPropTypes} from 'helpers/propTypes'
import Carousel from 'nuka-carousel'
import classNames from 'classnames'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import Dots from 'material-ui-dots'
import React, {useCallback, useEffect, useRef, useState} from 'react'

import {formRedirection} from 'helpers/utils'
import ContentSection from 'components/UI/ContentSection'
import Header from 'components/TechnologyPartner/Header'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import RoundButtonSlider from 'components/UI/RoundButtonSlider'
import Section from 'components/UI/Section'
import SwitchForm from 'components/UI/SwitchForm'
import useIsMobile from 'hooks/useIsMobile'
import useIsSmallMobile from 'hooks/useIsSmallMobile'

import ContactIcon from '../../../images/icon-partners.svg'

import useStyles from './styles'

const PartnerContent = ({
  partner,
  insights,
  keyplays,
  countryData,
  formData,
  location,
  siteURL,
  defaultLabels,
  whyKeyrusSection,
  demoFormTitle,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const isSmallMobile = useIsSmallMobile()
  const moreAboutContent = useRef(null)

  const [showContactLightBox, setShowContactLightBox] = useState(false)
  const [alreadyDisplayedLightBox, setAlreadyDisplayedLightBox] =
    useState(false)
  const [clickAnimationClass, setClickAnimationClass] = useState(false)
  const [insightsIndex, setInsightsIndex] = useState(0)
  const [keyplaysIndex, setKeyplaysIndex] = useState(0)

  const scrollToDiscoverMore = () => {
    const currentPosY = window.scrollY
    const distanceFromMoreAboutContent =
      moreAboutContent.current.getBoundingClientRect().top

    window.scrollTo({
      top: currentPosY + distanceFromMoreAboutContent - (isMobile ? 80 : 120),
      behavior: 'smooth',
    })
  }

  const getArrayKey = (arr, source) => R.pathOr(null, arr, source)

  const getLocalizedContentForKey = key => {
    const defaultContent = getArrayKey([key], partner)

    if (!partner[`${key}CountrySpecific`]) {
      return R.pathOr(defaultContent, [key], defaultContent)
    }

    const localizedContent = partner[`${key}CountrySpecific`].find(elem =>
      elem.countries.find(
        country => country.technicalName === countryData.technicalName,
      ),
    )

    if (localizedContent) {
      return localizedContent?.content?.content || localizedContent?.content
    }

    return R.pathOr(defaultContent, [key], defaultContent)
  }

  const {countryCode, navLocale, salesforce} = countryData
  const {
    insightsSectionDefaultLabel,
    playbookSectionDefaultLabel,
    discoverMoreCtaDefaultLabel,
    discoverMoreSectionDefaultLabel,
  } = defaultLabels
  const discoverMoreContent = getLocalizedContentForKey('discoverMoreContent')

  const displayContactLightBox = () => {
    setShowContactLightBox(!showContactLightBox)
    setAlreadyDisplayedLightBox(true)
    setClickAnimationClass(true)
    setTimeout(() => setClickAnimationClass(false), 200)
    // eslint-disable-next-line no-use-before-define
    window.removeEventListener('scroll', onBottomOfPageReached)
  }

  const onBottomOfPageReached = useCallback(() => {
    const footerPos = document
      .getElementById('page-footer')
      .getBoundingClientRect().top

    if (footerPos - window.innerHeight < 200) {
      if (!showContactLightBox) {
        displayContactLightBox()
      }
    }
  }, [showContactLightBox])

  useEffect(() => {
    if (!alreadyDisplayedLightBox) {
      window.addEventListener('scroll', onBottomOfPageReached)
    }

    return () => {
      window.removeEventListener('scroll', onBottomOfPageReached)
    }
  }, [onBottomOfPageReached])

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={classes.wrapper}>
      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <Header
          partner={partner}
          countryData={countryData}
          discoverMoreCtaDefaultLabel={discoverMoreCtaDefaultLabel}
        />
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          {discoverMoreContent && (
            <div className={classes.discoverMoreButton}>
              <RoundButton
                isAction
                isFilledForce
                color="secondary"
                action={() => scrollToDiscoverMore()}
              >
                {partner.discoverMoreCtaLabel?.discoverMoreCtaLabel ||
                  discoverMoreCtaDefaultLabel}
              </RoundButton>
            </div>
          )}
          <div className={classes.insightKeyplaysContainer}>
            {insights.length > 0 && (
              <div className={classes.carouselContainer}>
                <div className={classes.titleAndButtonsContainer}>
                  <p className={classes.title}>
                    {getLocalizedContentForKey('insightsSectionLabel') ||
                      insightsSectionDefaultLabel}
                  </p>
                  {insights.length > (isSmallMobile ? 1 : 2) && (
                    <div className={classes.carouselButtonsContainer}>
                      <RoundButtonSlider
                        increment={() => setInsightsIndex(insightsIndex + 2)}
                        decrement={() => setInsightsIndex(insightsIndex - 2)}
                      />
                    </div>
                  )}
                </div>
                <Carousel
                  slideIndex={insightsIndex}
                  afterSlide={slideIndex => setInsightsIndex(slideIndex)}
                  wrapAround
                  withoutControls
                  slidesToShow={isSmallMobile ? 1 : 2}
                  cellSpacing={50}
                  dragging={insights.length > 2}
                  swiping={insights.length > 2}
                >
                  {insights.map(insight => (
                    <Link
                      to={`../insights/${insight.slug}`}
                      className={classes.insightCard}
                    >
                      <GatsbyImage
                        image={R.pathOr(
                          null,
                          ['image', 'gatsbyImageData'],
                          insight,
                        )}
                        className={classes.insightImage}
                      />
                      <p className={classes.type}>
                        {R.pathOr('', ['type', 'name'], insight)}
                      </p>
                      <p className={classes.insightAndKeyPlayTitle}>
                        {R.pathOr('', ['title'], insight)}
                      </p>
                      <p className={classes.date}>
                        {R.pathOr('', ['publicationDate'], insight)}
                      </p>
                    </Link>
                  ))}
                </Carousel>
                {insights.length > (isSmallMobile ? 1 : 2) && (
                  <Dots
                    index={insightsIndex}
                    count={insights.length}
                    className={classes.dots}
                    onDotClick={target => setInsightsIndex(target)}
                  />
                )}
              </div>
            )}
            {keyplays.length > 0 && (
              <div className={classes.carouselContainer}>
                <div className={classes.titleAndButtonsContainer}>
                  <p className={classes.title}>
                    {getLocalizedContentForKey('playbookSectionLabel') ||
                      playbookSectionDefaultLabel}
                  </p>
                  {keyplays.length > (isSmallMobile ? 2 : 3) && (
                    <div className={classes.carouselButtonsContainer}>
                      <RoundButtonSlider
                        increment={() => setKeyplaysIndex(keyplaysIndex + 3)}
                        decrement={() => setKeyplaysIndex(keyplaysIndex - 3)}
                      />
                    </div>
                  )}
                </div>
                <Carousel
                  slideIndex={keyplaysIndex}
                  afterSlide={slideIndex => setKeyplaysIndex(slideIndex)}
                  wrapAround
                  withoutControls
                  slidesToShow={isSmallMobile ? 2 : 3}
                  cellSpacing={50}
                  dragging={keyplays.length > 3}
                  swiping={keyplays.length > 3}
                >
                  {keyplays.map(keyplay => (
                    <Link
                      to={`../playbook/${keyplay.slug}`}
                      className={classes.keyplayCard}
                    >
                      <img
                        src={`${keyplay.image.file.url}?w=260&h=200&q=100&fit=fill&r=4&fm=webp`}
                        alt={keyplay.title.title}
                        loading="lazy"
                        className={classes.keyplayImage}
                      />
                      <p className={classes.insightAndKeyPlayTitle}>
                        {keyplay.title.title}
                      </p>
                    </Link>
                  ))}
                </Carousel>
                {keyplays.length > (isSmallMobile ? 2 : 3) && (
                  <Dots
                    index={keyplaysIndex}
                    count={keyplays.length}
                    className={classes.dots}
                    onDotClick={target => setKeyplaysIndex(target)}
                  />
                )}
              </div>
            )}
          </div>
          {whyKeyrusSection && whyKeyrusSection.description && (
            <ContentSection
              showContent={whyKeyrusSection.showContentSection}
              title={whyKeyrusSection.title}
              variation={whyKeyrusSection.variation}
              description={whyKeyrusSection.description}
              isPaddingLess
              isJustified
            />
          )}
          {discoverMoreContent && (
            <div ref={moreAboutContent} className={classes.moreAboutContent}>
              <p className={classes.title}>
                {getLocalizedContentForKey('discoverMoreSectionLabel') ||
                  `${discoverMoreSectionDefaultLabel} ${partner.name}`}
              </p>
              <RawText text={discoverMoreContent} isJustified />
            </div>
          )}
        </Section>
      </Section>
      <div className={classes.getADemoContainer}>
        <div
          className={classNames(
            classes.contactLightBox,
            showContactLightBox ? classes.showLightBox : null,
          )}
        >
          {alreadyDisplayedLightBox && (
            <SwitchForm
              data={{...formData, title: demoFormTitle}}
              hubSpotCountry={salesforce}
              locale={navLocale}
              redirection={formRedirection(
                siteURL,
                countryCode,
                navLocale,
                'lead',
              )}
              location={location}
              isContact
            />
          )}
        </div>
        <button
          type="button"
          onClick={displayContactLightBox}
          className={classNames(classes.getADemoButton, {
            [classes.smallIcon]: clickAnimationClass,
          })}
        >
          {showContactLightBox ? (
            <CloseRoundedIcon className={classes.getADemoButtonIcon} />
          ) : (
            <img
              src={ContactIcon}
              className={classes.getADemoButtonIcon}
              alt="get a demo"
            />
          )}
        </button>
      </div>
    </div>
  )
}

PartnerContent.propTypes = partnerContentPropTypes

PartnerContent.defaultProps = {
  countryData: null,
  insights: null,
  keyplays: null,
  partner: null,
}

export default PartnerContent
