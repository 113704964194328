import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({fontSizes, palette}) => ({
  descriptionContainer: {
    marginLeft: 0,
    paddingLeft: 0,
    maxWidth: 500,
    '& a': {
      color: palette.primary.main,
      textDecoration: 'underline !important',
      fontSizes: fontSizes.description,
      '&:hover': {
        color: `${palette.primary.main} !important`,
      },
    },
  },
}))

export default useStyles
