import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, fontSizes, spacing, breakpoints}) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  mainContentContainer: {
    padding: spacing(0, 15),
    [breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  discoverMoreButton: {
    marginBottom: spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: fontSizes.subTitle,
    marginTop: spacing(2.5),
  },
  filtersWrapper: {
    width: '90%',
    height: '100%',
    borderRight: '1px solid #d8d8d8',
    [breakpoints.down('sm')]: {
      width: '100%',
      border: 0,
    },
  },
  insightKeyplaysContainer: {
    marginBottom: spacing(10),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(5),
    },
  },
  titleAndButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  carouselButtonsContainer: {
    transform: 'scale(0.7)',
    transformOrigin: 'right',
  },
  insightCard: {
    color: palette.background.lightDark,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),

    '& > p': {
      margin: 0,
    },
  },
  insightImage: {
    borderRadius: '40px 7px',
    transform: 'translateZ(0)',
    width: '100%',
    aspectRatio: 16 / 9,
    [breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  type: {
    color: palette.primary.main,
    fontFamily: 'CamptonSemiBold',
    fontSize: fontSizes.medium,
  },
  insightAndKeyPlayTitle: {
    fontFamily: 'CamptonMedium',
    fontSize: 18,
    margin: 0,
  },
  date: {
    fontFamily: 'CamptonLight',
    fontSize: '16px',
    color: palette.text.grey,
  },
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dots: {
    '& > div': {
      padding: 0,
      height: 15,
      marginTop: spacing(1),
      '& > div[class^="MuiPaper"], & > div > div': {
        background: palette.primary.main,
      },
    },
  },
  keyplayCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
    color: palette.background.lightDark,
  },
  keyplayImage: {
    width: '100%',
    borderRadius: spacing(0.5),
    transform: 'translateZ(0)',
  },
  moreAboutContent: {
    marginTop: spacing(5),
  },
  getADemoContainer: {
    zIndex: 19,
    position: 'sticky',
    top: spacing(6.25),
    bottom: spacing(6.25),
    marginRight: '10%',
    marginBottom: spacing(12.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
    [breakpoints.down('sm')]: {
      margin: 0,
      padding: spacing(0, 2),
    },
  },
  getADemoButton: {
    border: 0,
    width: 50,
    height: 50,
    borderRadius: '50%',
    background: palette.primary.main,
    color: 'white',
    fontSize: 30,
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.1)',
      boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 10px, rgb(0 0 0 / 20%) 0px 4px 28px',
    },
  },
  smallIcon: {
    transform: 'rotateZ(30deg)',

    '& > img, & > svg': {
      width: 20,
    },

    '&:hover': {
      transform: 'rotateZ(30deg) scale(1.1)',
    },
  },
  getADemoButtonIcon: {
    fontSize: 'inherit',
    width: '100%',
    transition: 'all .2s',
  },
  contactLightBox: {
    transition: 'opacity .4s, bottom .4s',
    animationTimingFunction: 'ease-in',
    position: 'absolute',
    bottom: 0,
    opacity: 0,
    height: 0,
    marginBottom: spacing(2.5),
    aspectRatio: 0.9,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      backgroundColor: 'white',
      borderRadius: '0 10px 10px 0',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgray',
      borderRadius: '10px',
    },
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.7)',
    borderRadius: 10,
    [breakpoints.down('xs')]: {
      marginBottom: spacing(2),
      marginLeft: spacing(2),
      aspectRatio: 'unset',
    },
  },
  showLightBox: {
    opacity: 1,
    bottom: spacing(5.5),
    height: '60vh',
  },
}))

export default useStyles
